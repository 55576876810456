


























import { defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  setup(_, { root }) {
    const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

    const form = ref<any>(null)

    const state = reactive({
      loading: false,
      isFormValid: true,
      success: false,
      error: false,
      challenge: {
        a: 0,
        b: 0,
        operation: '+'
      }
    })

    const model = reactive({
      email: '',
      challenge: ''
    })

    const rules = {
      email: [(v: string) => !!v || 'Podaj adres email'],
      challenge: [
        (v: string) => !!v || 'Rozwiąż zagadkę',
        (v: string) => parseInt(v) === state.challenge.a + state.challenge.b || 'Błędne rozwiązanie'
      ]
    }

    const newChallenge = () => {
      state.challenge.a = Math.floor(Math.random() * 10)
      state.challenge.b = Math.floor(Math.random() * 10)

      model.challenge = ''
    }

    const resetForm = () => {
      model.email = ''
      model.challenge = ''

      newChallenge()
    }

    const onSubmit = async () => {
      await form.value.validate()

      if (state.isFormValid) {
        state.loading = true
        state.success = false
        state.error = false

        axiosInstance
          .post('auth/reset-password', { email: model.email })
          .then(({ data }) => {
            if (data.success) {
              state.success = true
            } else {
              state.error = true
            }
          })
          .catch(() => {
            state.error = true
          })
          .finally(() => {
            state.loading = false
            resetForm()
          })
      }
    }

    onMounted(() => {
      newChallenge()
    })

    watch(() => state.success, success => {
      if (success) root.$router.push({ query: { success: 'forgot-password' } })
      else root.$router.push({ query: { success: null } })
    })

    watch(() => state.error, error => {
      if (error) root.$router.push({ query: { error: 'forgot-password' } })
      else root.$router.push({ query: { error: null } })
    })

    return { form, state, model, rules, onSubmit }
  }
})
